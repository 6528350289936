<template>
<div class="main-content donations">
    <h1>Manage Donations</h1>
    <div class="filtered-list-subhead">
        <font-awesome-icon class="mobile-filters-button" :icon="showFilters ? 'times-circle' : 'plus-circle'" @click="showFilters = !showFilters" />
        <div class="search-controls">
            <input type="search" v-model="filters.search_term" placeholder="Search..." @search="getList()">
            <div class="search-control-button" @click="getList()"><font-awesome-icon icon="search" class="search-icon"/></div>
        </div>
        <div></div>
    	<div class="page-controls">
    		<Pagination :paging="paging" @updatePage="goToPage" />
    	</div>
    </div>
    <div class="filtered-list">
        <div v-if="showFilters" class="filtered-list-sidebar">
            <div v-if="detail.donation_id" class="filtered-list-sidebar-controls">
                <label>Actions...</label>
                <div v-if="!editContact" class="button button-dark" @click="sendingEmail = true">Email Donor</div>
                <div v-if="!editContact" class="button button-dark" @click="message = ''; editContact = !editContact">Edit Contact Info</div>
        		<div v-if="editContact" class="button cancel" @click="editContact = false; getItem(detail.registration_id)">Cancel Edit</div>
        		<div v-if="editContact" class="button save" @click="saveItem()"><LabelWithLoadingIcon :loading="saving" label="Save Contact" /></div>
                <Message :message="message" :error="errorStatus" delay="3" :key="message" />
                <hr>
                <div class="button button-dark" @click="backToList()">Back To List</div>
            </div>
            <div v-else class="filtered-list-sidebar-controls">
                <label>Filters...</label>
                <select v-model="filters.designation_id" @change="getList()">
                    <option value="">Designation...</option>
                    <option v-for="(designation, index) in designations" :value="designation.designation_id">{{ designation.description }}</option>
                </select>
                <div class="input-with-label">
                    <label>From</label>
                    <input type="date" v-model="filters.start_date" @change="getList()" />
                </div>
                <div class="input-with-label">
                    <label>To</label>
                    <input type="date" v-model="filters.end_date" @change="getList()" />
                </div>
                <div :class="['button', hasFilters ? 'button-dark' : 'disabled']" @click="clearFilters()">Clear Filters</div>
                <hr>
                <label>Actions...</label>
                <div :class="['button', list.length > 0 ? 'button-dark' : 'disabled']" @click="doExport()">Export List</div>
            </div>
        </div>

        <div v-if="!detail.donation_id" class="filtered-list-content">
            <table class="filtered-list-table">
                <tr class="clickable-list-header">
                    <th>Date</th>
                    <th>Number</th>
                    <th>Donor</th>
                    <th>Contact</th>
                    <th>Designation</th>
                    <th class="right">Amount</th>
                </tr>
                <tr v-for="(item, index) in list" class="clickable-list" @click="getItemRoute(item.donation_id)">
                    <td>{{ item.donation_date }}</td>
                    <td>{{ item.donation_number }}</td>
                    <td>
                        {{ item.first_name + ' ' + item.middle_name + ' ' + item.last_name }}<br>
                        {{ item.address_1 + ', ' + item.city + ', ' + item.state + ' ' + item.postal_code }}
                    </td>
                    <td>
                        {{ item.phone_number }}<br>
                        {{ item.email_address }}
                    </td>
                    <td>{{ item.designation }}</td>
                    <td class="right">{{ item.payment_amount }}</td>
                </tr>
            </table>
        </div>

        <div v-else class="filtered-list-item">
            <div class="filtered-list-item-two-columns">
                <ContactForm v-if="editContact" :contact="detail" :required="['first_name','last_name','email_address','phone_number']" @update="updateItemFromChild" />
                <div v-else>
                    <div class="section-heading">Donation Details</div>
                    <div class="three-columns">
                        <label>Date</label>
                        <label>Number</label>
                        <label>Payment</label>
                        <div>{{ detail.donation_date }}</div>
                        <div>{{ detail.donation_number }}</div>
                        <div>{{ detail.payment_amount }}</div>
                    </div>
                    <label>Donor</label>
                    {{ localGetFullName(detail) }}<br>
                    {{ detail.address_1 }}<br>
                    {{ detail.city }}, {{ detail.state}} {{ detail.postal_code }}
                    <label>Phone</label>
                    {{ detail.phone_number}}
                    <label>Email</label>
                    {{ detail.email_address }}
                    <div v-for="(value, label) in detail.extra_data">
                        <label>{{ localCaptializeWords(label) }}</label>
                        {{ value }}
                    </div>
                    <div v-if="detail.notes">
                        <label>Notes</label>
                        {{ detail.notes }}
                    </div>
                </div>
                <ContactEvents :contact_id="detail.contact_id" :key="emailSent" />
            </div>


        </div>

    </div>

    <MessageModal v-if="message" :message="message" :loading="loading" :errorStatus="errorStatus" @dismiss="message = ''" />

    <SendEmail v-if="sendingEmail" :contact_id="detail.contact_id" @cancel="sendingEmail = false" @sent="emailSent++; sendingEmail = false"/>

    <iframe id="download" src="" style="display: none;"></iframe>

</div>
</template>

<script>
import Pagination from '@/components/Pagination.vue'
import ContactForm from '@/components/ContactForm.vue'
import ContactEvents from '@/components/ContactEvents.vue'
import SendEmail from '@/components/SendEmail.vue'
import LabelWithLoadingIcon from '@/components/LabelWithLoadingIcon.vue'
import MessageModal from '@/components/MessageModal.vue'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
export default {
    name: 'Donations',
    components: {
        Pagination,
        ContactForm,
        ContactEvents,
        SendEmail,
        LabelWithLoadingIcon,
        MessageModal
    },
    data() {
        return {
            loading: false,
            message: '',
            errorStatus: false,
            filterMessage: '',
            donationId: '',
            interests: [],
            designations: [],
            events: [],
            list: [],
            detail: {},
            paging: {},
            filters: {
                page: 1,
                search_term: '',
                do_export: false,
                designation_id: '',
                start_date: '',
                end_date: ''
            },
            editContact: false,
            saving: false,
            saved: false,
            showFilters: false,
            sendingEmail: false,
            emailSent: 1
        }
    },
    created() {
        this.showFilters = window.matchMedia("(min-width: 768px)").matches
        this.getDesignations()
        // TODO: can we use "action group" to invoke more than one action with the same network call?
        this.donationId = this.$route.params.donation_id
        if (this.donationId) {
            this.getItem(this.donationId)
        } else {
            this.getList()
        }
    },
    watch: {
        '$route.params.donation_id': function (donationId) {
            if (donationId) {
                this.donationId = donationId
                this.getItem(donationId)
            }
        }
    },
    computed: {
        // mix the getters into computed with object spread operator
        ...mapGetters([
            'getUser',
            'getDownloadEndpoint'
        ]),
        hasFilters() {
            return this.filters.page > 1 || this.filters.search_term || this.filters.designation_id || this.filters.start_date || this.filters.end_date
        }
    },
	methods: {
        ...mapActions([
            'apiCall', // map `this.apiCall()` to `this.$store.dispatch('apiCall')`
        ]),
        localCaptializeWords(words) {
            return this.caplitalizeWords(words)
        },
        localGetFullName(name) {
            return this.getFullName(name)
        },
        getInterests() {
    		this.apiCall({ destination: 'get_list_interests' }).then(obj => {
    			if (obj.status == 'success') {
                    this.interests = obj.interest_list
                }
            })
        },
        getDesignations() {
    		this.apiCall({ destination: 'get_list_designations' }).then(obj => {
    			if (obj.status == 'success') {
                    this.designations = obj.designation_list
                }
            })
        },
        getList() {
		    this.message = this.filters.do_export ? 'Getting the export list...' : ''
            this.errorStatus = false
			this.loading = true
    		this.apiCall({ destination: 'get_list_donations', data: this.filters }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
                    if (this.filters.do_export) {
        				// set hidden iframe src to the API's download URL
        				document.getElementById("download").src = this.getDownloadEndpoint + "?file_name=" + obj.file_name + "&output_file_name=" + obj.file_name
        				this.message = obj.total_items + ' items exported'
        				// var timer = setTimeout((function() {
        				// 	this.filterMessage = ''
        				// }.bind(this)),3000)
                        this.filters.do_export = false
                    } else {
                        this.detail = {}
    					this.list = obj.list
                        this.paging = obj.paging
                        window.scrollTo(0, 0);
                    }
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
        },
        doExport() {
			this.message = 'Generating export file...'
            this.filters.do_export = true
            this.getList()
        },
        setFilter(which) {
            switch (which) {
                // case 'paid':
                //     this.filters.not_paid = ''
                //     break
                // case 'not_paid':
                //     this.filters.paid = ''
                //     break
            }
            this.getList()
        },
        clearFilters() {
            this.filters = {
                page: 1,
                search_term: '',
                do_export: false,
                designation_id: '',
                start_date: '',
                end_date: ''
            }
            this.getList()
        },
        doSearch() {
            if (this.donationId) {
                this.editContact = false
                this.detail = {}
                this.donationId = ''
                this.$router.push('/donations')
            }
            this.getList()
        },
		goToPage(page) {
			this.filters.page = page
			this.getList()
		},
        getItemRoute(donationId) {
            this.$router.push('/donations/detail/' + donationId)
        },
        getItem(donationId) {
		    this.message = ''
            this.errorStatus = false
			this.loading = true
    		this.apiCall({ destination: 'get_item_donation', data: { donation_id: donationId } }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
					this.detail = obj.detail
                    window.scrollTo(0, 0);
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
        },
        updateItemFromChild(contactCopy) {
            // ContactForm emits an update event when keyup is fired
			for (const [key, value] of Object.entries(contactCopy)) {
                this.detail[key] = value
			}
        },
		saveItem() {
			if (this.saving) { return }
			this.message = ''
            this.errorStatus = false
			if (!(this.detail.first_name && this.detail.last_name) && !this.detail.company_name) {
				this.message = 'First and Last Name or Company Name are required.'
                this.errorStatus = true
				return false
			}
			if (!this.detail.address_1) {
				this.message = 'Address is required.'
                this.errorStatus = true
				return false
			}
			if (!this.detail.city) {
				this.message = 'City is required.'
                this.errorStatus = true
				return false
			}
			if (!this.detail.state) {
				this.message = 'State is required.'
                this.errorStatus = true
				return false
			}
			if (!this.detail.postal_code) {
				this.message = 'Postal Code is required.'
                this.errorStatus = true
				return false
			}
			this.saving = true
    		this.apiCall({ destination: 'save_item_contact', data: this.detail }).then(obj => {
				this.saving = false
    			if (obj.status == 'success') {
					this.detail.contact_id = obj.contact_id
					this.message = 'Contact info saved.'
                    setTimeout(() => { this.message = '' }, 2000)
					this.saved = true
                    this.editContact = false
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
		},
        backToList() {
            this.editContact = false
            this.detail = {}
            this.donationId = ''
            this.$router.push('/donations')
            if (this.saved) {
                this.saved = false
                this.getList()
            }
        }
    }
}
</script>
